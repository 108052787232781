import React from 'react';
import styles from './Order.module.scss';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import http from '@/assets/api/http';
import { message } from 'antd';
import Alipay from '@/pay/Alipay';
import { xssd_href } from '@/assets/config';
import { getLocalStorage } from '@/assets/js/storage';

class Index extends React.Component {

    state = {
        orderId: '',
        goodsId: '',
        mainImg: '',
        goodsName: '',
        sku: [],
        count: 0,
        salePrice: 0,
        consigneeName: '',//收货人姓名
        addrDetail: '',//收货人地址
        tel: null,//电话号码
    }

    componentDidMount() {
        const { match } = this.props;
        const { id, count } = match.params;

        //获取收货信息
        if (getLocalStorage('reservation')) {
            const { consigneeName, addrDetail, tel } = JSON.parse(getLocalStorage('reservation'));
            this.setState({
                consigneeName,
                addrDetail,
                tel,
            });
        }

        http('/api/shopingMail/goodsDetail', { id }, 'POST').then((response) => {
            if (response.status == 200) {
                console.log(response);
                const { id, mainImgUrl, goodsName, specName, salePrice } = response.result;
                let sku = specName.split(';');
                this.setState({
                    goodsId: id,
                    mainImg: mainImgUrl,
                    goodsName,
                    salePrice,
                    sku,
                    count
                });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _sku = (code, value, index) => {
        return (<div key={index} className={styles.sku}>
            <div className={styles.code}>{code}：</div>
            <div className={styles.value}>{value}</div>
        </div>);
    }

    _submit = () => {
        const { goodsId, count, consigneeName, addrDetail, tel } = this.state;
        http('/api/shopingMail/createOrder', { goodsId, goodsCount: count, payType: '支付宝', nickName: '', addrDetail, consigneeName, remark: '', tel }, 'POST').then((response) => {
            if (response.status == 200) {
                const orderId = response.result;
                this.setState({ orderId });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _paySucess = () => {
        this.props.history.push({
            pathname: '/'
        });
    }

    render() {
        const { mainImg, goodsName, sku, salePrice, count, orderId, consigneeName, addrDetail, tel } = this.state;
        const totalPrice = salePrice * count;
        console.log(this.state);
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.topbar}>
                        <div className={styles.nav}>
                            <div className={styles.sep}></div>
                            <a className={styles.a} href={xssd_href} target='_self'><img className={styles.icon} src={require('@/assets/image/home_icon.png')} /></a>
                            <div className={styles.sep}></div>
                            <a className={styles.a} href={location.origin}>优选行商城</a>
                            <div className={styles.sep}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.order}>
                    <div className={styles.title}>
                        <div className={styles.label}>订单详情</div>
                    </div>
                    <div className={styles.goods}>
                        <div className={styles.left}>
                            <div className={styles.box}>
                                <img className={styles.img} src={mainImg} />
                                <div className={styles.info}>
                                    <div className={styles.name}>{goodsName}</div>
                                    {sku.map((item, index) => {
                                        const code = item.split(':')[0];
                                        const value = item.split(':')[1];
                                        return this._sku(code, value, index);
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <span className={styles.rmb}>¥</span><span className={styles.price}>{regFenToYuan(salePrice)} × {count}</span>
                        </div>
                    </div>
                    <div className={styles.distMode}>
                        <span className={styles.code}>配送方式：</span><span className={styles.value}>包邮</span>
                    </div>
                    <div className={styles.userinfo}>
                        <div><span className={styles.code}>收货人姓名：</span><span className={styles.value}>{consigneeName}</span></div>
                        <div><span className={styles.code}>收货地址：</span><span className={styles.value}>{addrDetail}</span></div>
                        <div><span className={styles.code}>联系电话：</span><span className={styles.value}>{tel}</span></div>
                    </div>
                    <div className={styles.count}>
                        <div className={styles.item}>
                            <div className={styles.label}>商品件数：</div>
                            <div className={styles.value}>{count}件</div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>商品总价：</div>
                            <div className={styles.value}><span className={styles.rmb}>¥</span><span>{regFenToYuan(totalPrice)}</span></div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>运费：</div>
                            <div className={styles.value}><span className={styles.rmb}>¥</span>0</div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>应付总额：</div>
                            <div className={styles.value}><span className={styles.rmb}>¥</span>{regFenToYuan(totalPrice)}</div>
                        </div>
                    </div>
                    <div className={styles.submit}>
                        <div className={styles.button} onClick={this._submit}>
                            确认订单
                        </div>
                    </div>
                </div>
                <Alipay
                    orderId={orderId}
                    sucessUrl={location.origin}
                    onSucess={this._paySucess}
                />
            </div>
        );
    }
}

Index.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
};

export default Index;