/**
 * 带标题的textare输入框组件
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const { TextArea } = Input;
class TextAreaModule extends React.Component {

    _onChange = (e) => {
        const { bindThis, bindName, onChange } = this.props;
        if(onChange){
            onChange(e.target.value);
        }
        if(bindThis && bindName){
            bindThis.setState({[bindName]: e.target.value});
        }
    }

    render(){
        const { label, placeholder, disabled, style, inputStyle, value, rows } = this.props;

        return(
            <div style={style} className={styles.container}>
                <div className={styles.label}>{label}</div>
                <div className={styles.inputbox}>
                    <TextArea
                        style={inputStyle}
                        className={styles.input} 
                        rows={rows}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={this._onChange}
                        value={value}  
                    />
                </div>
            </div>
        );
    }
}

TextAreaModule.defaultProps = {
    disabled: false,
    rows: 5,
};

TextAreaModule.propTypes = {
    bindThis: PropTypes.object, // This双向绑定指向
    bindName: PropTypes.string, // 双向绑定的属性名称
    style: PropTypes.object, // 总样式
    inputStyle: PropTypes.object, // 输入框样式
    label: PropTypes.string, // 标题名称
    placeholder: PropTypes.string, // 提示信息
    disabled: PropTypes.bool, // 是否为禁用状态，默认为 false
    onChange: PropTypes.func, // 输入框内容变化时的回调
    // 输入框内容，为字符串或数字(双向绑定)
    value:  PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]), 
    rows: PropTypes.number, // 文本区域中显示的输入行数
};

export default TextAreaModule;