import React from 'react';
import styles from './Alipay.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Modal } from 'antd';

class Index extends React.Component {

    state = {
        orderId: '', // 订单ID
        alipayForm: '', // 支付宝表单
    }
    form = null;

    UNSAFE_componentWillReceiveProps(props) {
        if (props.orderId !== this.state.orderId) {
            this.setState({ orderId: props.orderId }, () => {
                this._createAlipayAction(props.orderId);
            });
        }
    }

    _createAlipayAction = (orderId) => {
        const { createAliPayOrderAPI, sucessUrl } = this.props;
        http(createAliPayOrderAPI, { orderId, url: sucessUrl }, 'POST').then((response) => {
            if (response.status == 200) {
                const alipayForm = response.result;
                console.log(alipayForm);
                this._submitForm(alipayForm);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _submitForm = (formStr = '') => {
        this.setState({
            alipayForm: formStr,
        });
        let form = this.form.querySelector('form');
        form.setAttribute('target', '_blank');
        form.submit();
        this._showConfirm();
    }

    _showConfirm = () => {
        Modal.confirm({
            title: '请确认该订单是否支付成功！',
            centered: true,
            className: styles.confirm,
            okText: '已完成支付',
            cancelText: '支付失败，重新发起支付',
            onOk: () => {
                this._queryOrder();
            },
            onCancel() { },
        });
    }

    _queryOrder = () => {
        console.log('查询订单');
        const { orderId, queryAlipayOrderAPI } = this.props;
        http(queryAlipayOrderAPI, { outTradeNo: orderId }, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result.tradeStatus == 'TRADE_SUCCESS') { //支付成功
                    message.success('支付成功');
                    this.props.onSucess && this.props.onSucess();
                } else {
                    message.error('支付失败，请重新支付');
                    this.props.onFail && this.props.onFail();
                }
            }
        }).catch((e) => {
            message.error(e.message);
            this.props.onFail && this.props.onFail();
        });
    }

    render() {
        const { alipayForm } = this.state;

        return (
            <>
                <div dangerouslySetInnerHTML={{ __html: alipayForm }} ref={(form) => this.form = form}></div>
            </>
        );
    }
}

Index.defaultProps = {
    createAliPayOrderAPI: '/api/shopingMail/createAliPayOrder',
    queryAlipayOrderAPI: '/api/shopingMail/aliPayOrderQuery'
};

Index.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    orderId: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]),
    sucessUrl: PropTypes.string.isRequired,
    onSucess: PropTypes.func,
    onFail: PropTypes.func,
    createAliPayOrderAPI: PropTypes.string,
    queryAlipayOrderAPI: PropTypes.string
};

export default Index;