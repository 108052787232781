/**
 * 带全选的复选框组件
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const CheckboxGroup = Checkbox.Group;
class CheckboxModule extends React.Component {

    state = {
        checkedList: [],
        plainOptions: [],
        indeterminate: true,
        checkAll: false,
    };

    componentDidMount() {
        const { options, defaultCheckedList } = this.props;
        this.setState({
            plainOptions: options,
            checkedList: defaultCheckedList,
        });
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //   const { defaultCheckedList, } = nextProps;
    //   // 当传入的defaultCheckedList发生变化的时候，更新state
    //   if (!prevState.checkAll) {
    //     if (defaultCheckedList.sort().toString() !== prevState.checkedList.sort().toString()) {
    //       return {
    //         checkedList: defaultCheckedList,
    //       };
    //     }
    //   }
    //   // 否则，对于state不进行任何操作
    //   return null;
    // }


    onChange = checkedList => {
        const { plainOptions } = this.state;
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
            checkAll: checkedList.length === plainOptions.length,
        });
        if (this.props.onChange) this.props.onChange(checkedList);
    };

    onCheckAllChange = e => {
        const { plainOptions } = this.state;
        this.setState({
            checkedList: e.target.checked ? plainOptions : [],
            indeterminate: false,
            checkAll: e.target.checked,
        }, () => {
            if (this.props.checkboxonchange) this.props.checkboxonchange(this.state.checkedList);
        });

    };

    render() {
        const { placecontent, disabled, style, inputAllStyle, inputStyle } = this.props;
        const { plainOptions, checkedList, indeterminate, checkAll } = this.state;

        return (
            <div>
                <div style={style}>
                    <Checkbox
                        style={inputAllStyle}
                        disabled={disabled}
                        indeterminate={indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={checkAll}
                    >
                        {placecontent}
                    </Checkbox>
                </div>
                <CheckboxGroup
                    style={inputStyle}
                    disabled={disabled}
                    options={plainOptions}
                    value={checkedList}
                    onChange={this.onChange}
                />
            </div>
        );
    }
}

CheckboxModule.defaultProps = {
    disabled: false,
};

CheckboxModule.propTypes = {
    style: PropTypes.object, // 总样式
    inputAllStyle: PropTypes.object, // 全选边样式
    inputStyle: PropTypes.object, // 子复选框样式
    placecontent: PropTypes.string, // 全选可改变内容，默认为 Check all
    disabled: PropTypes.bool, // 是否为禁用状态，默认为 false
    onChange: PropTypes.func, // 单个变化时回调函数
    checkboxonchange: PropTypes.func, // 全选时变化时回调函数
    defaultCheckedList: PropTypes.array, // 子复选框指定选中的选项 配合initialValue使用
    options: PropTypes.array,// 所有选项
};

export default CheckboxModule;