/**
 * 底部横线+确认按钮+取消按钮组合组件
 */
import React from 'react';
import styles from './index.module.scss';
import OKButton from '../OKButton';
import CancelButton from '../CancelButton';
import PropTypes from 'prop-types';

class LineConfirmBtn extends React.Component {

    render(){
        const { style, okHandle, cancelHandle } = this.props;
        return(
            <div className={styles.container} style={style}>
                <div className={styles.line}></div>
                <div className={styles.button}>
                    <OKButton style={{width:'90px'}} label='确认' onClick={okHandle} />
                    <CancelButton style={{width:'90px', marginLeft: '30px'}} label='返回' onClick={cancelHandle} />
                </div>
            </div>
        );
    }
}

LineConfirmBtn.propTypes = {
    style: PropTypes.object, // 总样式
    okHandle: PropTypes.func, // 点击确认按钮时的回调函数
    cancelHandle: PropTypes.func, // 点击取消按钮时的回调函数
};

export default LineConfirmBtn;