import axios from 'axios';
import { getLocalStorage } from '@/assets/js/storage';
import { createHashHistory } from 'history';
import { message } from 'antd';

const history = createHashHistory();
// eslint-disable-next-line no-unused-vars
export default function () {
    let _prefix = '';

    // 请求域名
    if (process.env.NODE_ENV === 'production') {
        _prefix = `${process.env.HOST}/`;
    } else {
        _prefix = '/';
    }

    axios.defaults.baseURL = _prefix;

    // 请求超时时间
    axios.defaults.timeout = 10000;

    // 请求拦截，在头部加入token
    axios.interceptors.request.use(
        function (config) {
            let curtoken = '';
            if (getLocalStorage('auth')) {
                curtoken = JSON.parse(getLocalStorage('auth')).token;
            }
            if (curtoken) {
                config.headers['token'] = `${curtoken}`; // 存在将token写入请求头部"TOKEN"
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    // 请求结果
    axios.interceptors.response.use(
        function (response) {
            try {
                if (response.data.code == '500') {
                    if (response.data.message) {
                        message.error(response.data.message);
                    } else {
                        message.error('接口500错误：【' + response.config.url + '】');
                    }

                }
            } catch (e) {
                message.error('后台500错误返回格式不正确');
            }
            return response;
        },
        function (error) {
            if (error.response) {
                switch (error.response.data.status) {
                    case 3004:
                        history.push({ pathname: '/', state: { pageInfo: '' } }); // token失效
                        message.error('提示：由于您长时间未操作或在其它地方登录，请重新登录');
                        break;
                    case 500:
                        // 500 服务端请求错误
                        message.error('接口500错误：【' + error.response.data.path + '】');
                        return Promise.reject(error.response);
                    case 404:
                        // 找不到资源
                        message.error('接口404错误：【' + error.response.data.path + '】');
                        return Promise.reject(error.response);
                    default:
                        return Promise.reject(error.response);
                }
            }
        }
    );
}