import { Modal } from 'antd';

const { confirm } = Modal;

const showConfirm = (title, content, okcb, cancelcb) => {
    confirm({
        title: title,
        content: content,
        cancelText: '取消',
        okText: '确认',
        onOk() {
            if (okcb)
                okcb();
        },
        onCancel() {
            if (cancelcb)
                cancelcb();
        },
    });
};

export default showConfirm;