/**
 * 上传Excel组件
 */
import React from 'react';
import PropTypes from 'prop-types';
import { message, Upload, Button, Icon } from 'antd';
import { getLocalStorage } from '@/assets/js/storage';

class UploadExcel extends React.Component {

    render() {
        const { style, label, successHandle, failHandle, name, apiurl, loading } = this.props;
        let _prefix = '';
        if (process.env.NODE_ENV === 'production') {
            _prefix = `${process.env.HOST}/`;
        } else {
            _prefix = './';
        }
        let curtoken = '';
        if (getLocalStorage('auth')) {
            curtoken = JSON.parse(getLocalStorage('auth')).token;
        }
        const props = {
            name: name,
            accept: '.xls, .xlsx',
            action: _prefix + apiurl,
            showUploadList: false,
            headers: {
                authorization: 'authorization-text',
                token: curtoken
            },
            onChange(info) {
                if (info.file.status === 'done') {
                    if (info.file.response && info.file.response.status == 200) {
                        successHandle(info);
                    } else if (info.file.response && info.file.response.status == 201) {
                        failHandle(info);
                    } else {
                        message.error(info.file.response && info.file.response.message);
                    }
                } else if (info.file.status === 'error') {
                    message.error(info.file.response && info.file.response.message);
                }
            },
        };

        return (
            <Upload {...props} {...this.props} disabled={loading} style={style}>
                <Button type="primary" loading={loading}>{label}</Button>
            </Upload>
        );
    }
}

UploadExcel.defaultProps = {
    name: 'excel'
};

UploadExcel.propTypes = {
    style: PropTypes.object, // 样式
    label: PropTypes.string, // 标题名称
    name: PropTypes.string, // 发到后台的文件参数名
    apiurl: PropTypes.string, // 请求地址
    successHandle: PropTypes.func, // 导入成功
    failHandle: PropTypes.func,//导入失败
    loading: PropTypes.bool,//加载样式
    beforeUpload: PropTypes.func
};

export default UploadExcel;
