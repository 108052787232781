/**
 * 立即购买
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './ReservationModal.module.scss';
import { XInput, XInputNum } from '@/components/xqxc_ui';
import { setLocalStorage, removeLocalStorage } from '@/assets/js/storage';


class ReservationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            consigneeName: '',//收货人姓名
            addrDetail: '',//收货人地址
            tel: null,//电话号码
        };
    }

    // 确认
    _handleOk = () => {
        const { consigneeName, addrDetail, tel } = this.state;

        let telInputReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
        if (consigneeName == '') return message.warn('请输入收货人姓名。');
        if (addrDetail == '') return message.warn('请输入收货人地址。');
        // if (tel === null || !telInputReg.test(tel)) {
        //     return message.warn('请输入电话号码。');
        // }
        if (tel === null) return message.warn('请输入联系号码。');
        removeLocalStorage('reservation');//初始化清除原Storage
        this.setState({ confirmLoading: true }, () => {
            let stateParam = {
                consigneeName,
                addrDetail,
                tel
            };
            setLocalStorage('reservation', stateParam);
            this.setState({ visible: false, confirmLoading: false }, () => {
                this.props.closeMode(true);
            });
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { title, visible, confirmLoading, consigneeName, addrDetail, tel } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        inputStyle={{ width: '340px' }}
                        label='收货人姓名'
                        placeholder='请输入收货人姓名'
                        bindThis={this}
                        bindName='consigneeName'
                        value={consigneeName}
                    />
                    <XInput
                        style={{ marginTop: '30px', marginLeft: '14px' }}
                        inputStyle={{ width: '340px' }}
                        label='收货地址'
                        placeholder='请输入收货地址'
                        bindThis={this}
                        bindName='addrDetail'
                        value={addrDetail}
                    />
                    <XInputNum
                        style={{ marginTop: '30px', marginLeft: '14px' }}
                        inputStyle={{ width: '340px'}}
                        placeholder='请输入联系电话'
                        label='联系电话'
                        bindThis={this}
                        bindName='tel'
                        value={tel}
                        precision={0}
                        min={0}
                    />
                </div>
            </Modal>
        );
    }
}


ReservationModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default ReservationModal;