import 'react-app-polyfill/ie9'; // This must be the first line in src/index.js
import 'core-js/es/map';
import 'core-js/es/set';
import React from 'react';
import ReactDOM from 'react-dom';
import App from '@/App';
import ajaxConfig from '@/assets/api/ajaxConfig';
import antdConfig from '@/assets/js/antdConfig';
import '@/assets/css/base.scss';
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/es/locale-provider/zh_CN';

antdConfig(); // antd全局配置 
ajaxConfig(); // ajax全局配置 

ReactDOM.render(
    <LocaleProvider locale={zh_CN}>
        <App />
    </LocaleProvider>
    , document.getElementById('root')
);
