/**
 *  面包屑组件
 * */
import React from 'react';
import PropTypes from 'prop-types';

class Breadcrumb extends React.Component {

    render() {
        const { renderData } = this.props;
        let string = '主页';

        if (renderData.length != 0) {
            string = '';
            for (var i = 0; i < renderData.length; i++) {
                string += renderData[i].title + ' > ';
            }
            string = string.substr(0, string.length - 2); // 截取掉最后一个>符号
        }
        return (
            <div style={{ cursor: 'pointer' }}>{string}</div>
        );
    }
}

Breadcrumb.propTypes = {
    renderData: PropTypes.array // 渲染数据源，参数格式[{title:'', path:''}, ...]
};

export default Breadcrumb;