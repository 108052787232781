import React from 'react';
import styles from './GoodsDetail.module.scss';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import http from '@/assets/api/http';
import { message } from 'antd';
import { xssd_href } from '@/assets/config';
import ReservationModal from './modal/ReservationModal';

class Index extends React.Component {

    state = {
        goodsId: '', // 商品ID
        goodsCount: 1, // 商品数量
        mainImg: '', // 主图
        imgs: [], // 展示图
        goodsName: '', // 商品标题
        salePrice: 0, // 销售价格
        sku: [], // sku规格
        invQuantity: 0, // 库存
        goodsDesc: '', // 详情富文本

        currentOpenMode: '', // 当前弹窗
        openModeParam: {}, // 弹窗入参
        goodsProperty: null,
    }

    componentDidMount() {
        const { match } = this.props;
        const { id } = match.params;
        console.log(id);

        http('/api/shopingMail/goodsDetail', { id }, 'POST').then((response) => {
            if (response.status == 200) {
                const { id, mainImgUrl, imgs, goodsName, specName, salePrice, invQuantity, goodsDesc, goodsProperty, showStyle } = response.result;

                let showImgs = [];
                for (let i = 0; i < imgs.length; i++) {
                    if (i < 3) {
                        showImgs.push(imgs[i]);
                    } else {
                        break;
                    }
                }
                let sku = specName.split(';');
                this.setState({
                    goodsId: id,
                    mainImg: mainImgUrl,
                    imgs: showImgs,
                    goodsName,
                    salePrice: regFenToYuan(salePrice),
                    sku,
                    invQuantity,
                    goodsDesc,
                    goodsProperty,
                    showStyle,
                });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _imgShow = (mainImg, imgs) => {
        return (
            <>
                <div className={styles.big}>
                    <img className={styles.img} src={mainImg} />
                </div>
                <div className={styles.small}>
                    <ul className={styles.ul}>
                        {imgs.map((url) => {
                            return this._imgItem(url);
                        })}
                    </ul>
                </div>
            </>
        );
    }

    _imgItem = (url) => {
        return (
            <li className={styles.li} onClick={() => {
                this.setState({ mainImg: url });
            }}>
                <img className={styles.img} src={url} />
            </li>
        );
    }

    _sku = (code, value) => {
        return (<div className={styles.sku}>
            <div className={styles.code}>{code}：</div>
            <div className={styles.value}>{value}</div>
        </div>);
    }

    _submit = () => {
        this.setState({ currentOpenMode: 'reservation' });
    }

    // 弹窗
    _renderMode = () => {
        if (this.state.currentOpenMode == 'reservation') {
            return <ReservationModal title='填写收货信息' visible={true} closeMode={this._closeMode} />;
        } else {
            return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            const { goodsId, goodsCount } = this.state;
            this.props.history.push({
                pathname: '/order/' + goodsId + '/' + goodsCount
            });
        }
    }

    render() {
        const { mainImg, imgs, goodsName, salePrice, sku, goodsCount, invQuantity, goodsDesc, goodsProperty } = this.state;
        const ext = (goodsProperty && JSON.parse(goodsProperty)) || [];

        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.topbar}>
                        <div className={styles.nav}>
                            <div className={styles.sep}></div>
                            <a className={styles.a} href={xssd_href} target='_self'><img className={styles.icon} src={require('@/assets/image/home_icon.png')} /></a>
                            <div className={styles.sep}></div>
                            <a className={styles.a} href={location.origin}>优选行商城</a>
                            <div className={styles.sep}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.detail}>
                    <div className={styles.left}>
                        {this._imgShow(mainImg, imgs)}
                    </div>
                    <div className={styles.right}>
                        {
                            ext.length > 0 ?
                                <div className={styles.goodsProperty}>
                                    <div className={styles.title}>{goodsName}</div>
                                    <div className={styles.label}>{ext[0] ? ext[0] : ''}</div>
                                    <div className={styles.label}>{ext[1] ? ext[1] : ''}</div>
                                    <div className={styles.label}>{ext[2] ? ext[2] : ''}</div>
                                    <div className={styles.label}>{ext[3] ? ext[3] : ''}</div>
                                </div> :
                                <>
                                    <div className={styles.title}>
                                        {goodsName}
                                    </div>
                                    <div className={styles.price}>
                                        <span className={styles.label}>价格：</span><span className={styles.rmb}>¥</span><span className={styles.money}>{salePrice}</span>
                                    </div>
                                    {sku.map((item) => {
                                        const code = item.split(':')[0];
                                        const value = item.split(':')[1];
                                        return this._sku(code, value);
                                    })}
                                    <div className={styles.invQuantity}>
                                        <div className={styles.code}>数量：</div>
                                        <div className={styles.count}>
                                            <div className={styles.subtract} onClick={() => {
                                                if (goodsCount - 1 > 0) {
                                                    this.setState({ goodsCount: goodsCount - 1 });
                                                }
                                            }}>-</div>
                                            <div className={styles.num}>{goodsCount}</div>
                                            <div className={styles.add} onClick={() => {
                                                if (goodsCount + 1 <= invQuantity) {
                                                    this.setState({ goodsCount: goodsCount + 1 });
                                                }
                                            }}>+</div>
                                        </div>
                                        <div className={styles.unit}>件(库存{invQuantity}件)</div>
                                    </div>
                                    <div className={styles.submit} onClick={this._submit}>
                                        立即购买
                                    </div>
                                </>
                        }
                    </div>
                </div>
                <div className={styles.desc}>
                    <div className={styles.title}>
                        <div className={styles.label}>商品介绍</div>
                    </div>
                    <div className={styles.html} dangerouslySetInnerHTML={{ __html: goodsDesc }} />
                </div>
                {this._renderMode()}
            </div>
        );
    }
}

Index.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
};

export default Index;