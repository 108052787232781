import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './routes';

const App = () => {
    return (
        <Router>
            <Switch>
                {routes.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            exact={route.exact ? route.exact : false}
                            path={route.path}
                            render={(props) => (
                                <route.component {...props} routes={Array.isArray(route.routes) ? route.routes : []} />
                            )}
                        />
                    );
                })}
            </Switch>
        </Router>
    );
};

export default App;
