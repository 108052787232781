import Home from '../views/Home';
import GoodsDetail from '../views/GoodsDetail';
import Order from '../views/Order';

// 路由配置
const routes = [
    { path: '/', title: '商城', component: Home, exact: true },
    { path: '/home', component: Home },
    { path: '/goodsDetail/:id', title: '商品详情', component: GoodsDetail },
    { path: '/order/:id/:count', title: '商品详情', component: Order },
];

export default routes;