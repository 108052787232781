/**
 * 
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Tree, Input, Icon} from 'antd';

const { TreeNode } = Tree;
const Search = Input.Search;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const key = node.key;
    dataList.push({ key, title: node.title });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

//对于异步加载的子节点使用该key进行自增赋值
// let key = 10;
class TreeFunction extends React.Component {
  state = {
    expandedKeys: [],
    searchValue: '',
    autoExpandParent: true,
    gData: this.props.gDataSource,
  };

  componentDidMount() { }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };
  onSelect = (selectedKeys, info) => {
    /*用于打开该节点的详细信息*/
    // console.log('selected', selectedKeys, info);
    // console.log(info.selectedNodes);
    this.props.onSelectDate(selectedKeys,info);
  };
  onChange = e => {
    const value = e.target.value;
    const expandedKeys = dataList
      .map(item => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, this.state.gData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    this.setState({
      expandedKeys,
      searchValue: value,
      autoExpandParent: true,
    });
  };
  render() {
    const { searchValue, expandedKeys, autoExpandParent, gData } = this.state;
    const { styleSearch, styleTrees, styleTreesbox, placeholder,defaultExpandAll } = this.props;
    generateList(gData);
    const loop = data =>
      data.map(item => {
        const index = item.title.indexOf(searchValue);
        const beforeStr = item.title.substr(0, index);
        const afterStr = item.title.substr(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: '#f50' }}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
              <span>{item.title}</span>
            );
        if (item.children) {
          return (
            <TreeNode key={item.key} title={title}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.key} title={title} />;
      });
    return (
      <div style={styleTreesbox} className={styles.treesbox}>
        <Search style={styleSearch} placeholder={placeholder} onChange={this.onChange} />
        <div style={styleTrees} className={styles.trees}>
          <Tree
            blockNode
            defaultExpandAll={defaultExpandAll}
            switcherIcon={<Icon type="down" />}
            onSelect={this.onSelect}
            onExpand={this.onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
          >
            {loop(gData)}
          </Tree>
        </div>
      </div>
    );
  }
}

TreeFunction.propTypes = {
  onSelectDate: PropTypes.func,// 变化时回调函数
  styleSearch: PropTypes.object, // Search样式
  styleTrees: PropTypes.object, // trees样式
  styleTreesbox: PropTypes.object, // treesbox样式
  placeholder: PropTypes.string, //Search-placeholder
  gDataSource: PropTypes.array,//数据
  defaultExpandAll: PropTypes.bool,//默认展开所有树节点
  // expandedKeys: PropTypes.array,//（受控）展开指定的树节点
};

export default TreeFunction;