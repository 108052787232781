import React from 'react';
import styles from './Home.module.scss';
import http from '@/assets/api/http';
import { message } from 'antd';
import { regFenToYuan } from '@/util/money';
import PropTypes from 'prop-types';
import { xssd_href } from '@/assets/config';

class Index extends React.Component {

    state = {
        hotList: [],
        elecList: [],
        aroundList: []
    }

    _goodClick = (id) => {
        this.props.history.push({
            pathname: '/goodsDetail/' + id
        });
    }

    componentDidMount() {
        // http('/api/shopingMail/listGoods', { categoryName: '医药用品', pageSize: '3', pageNum: '1' }, 'POST').then((response) => {
        //     if (response.status == 200) {
        //         const { dataList } = response.result;
        //         this.setState({ hotList: dataList });
        //     }
        // }).catch((e) => {
        //     message.error(e.message);
        // });

        http('/api/shopingMail/listGoods', { categoryName: '优选行', pageSize: '100', pageNum: '1' }, 'POST').then((response) => {
            if (response.status == 200) {
                const { dataList } = response.result;
                this.setState({ elecList: dataList });
            }
        }).catch((e) => {
            message.error(e.message);
        });

        // http('/api/shopingMail/listGoods', { categoryName: '生活周边', pageSize: '100', pageNum: '1' }, 'POST').then((response) => {
        //     if (response.status == 200) {
        //         const { dataList } = response.result;
        //         this.setState({ aroundList: dataList });
        //     }
        // }).catch((e) => {
        //     message.error(e.message);
        // });
    }

    _hotGood = (id, mainImgUrl, goodsName, specName, salePrice, goodsProperty) => {
        const price = regFenToYuan(salePrice);
        const ext = (goodsProperty && JSON.parse(goodsProperty)) || [];
        return <>
            {
                ext.length > 0 ?
                    <div key={id} className={styles.good} onClick={() => this._goodClick(id)}>
                        <img className={styles.img} src={mainImgUrl} />
                        <div className={styles.goodsProperty}>
                            <p className={styles.p1}>{goodsName}</p>
                            <p className={styles.p2}>{ext[0]}</p>
                            <p className={styles.p2}>{ext[1]}</p>
                            <p className={styles.p2}>{ext[2]}</p>
                            <p className={styles.p3}>{ext[3]}</p>
                        </div>
                    </div> :

                    <div key={id} className={styles.good} onClick={() => this._goodClick(id)}>
                        <img className={styles.img} src={mainImgUrl} />
                        <div className={styles.desp}>
                            <p className={styles.p1}>{goodsName}</p>
                            <p className={styles.p2}>{specName}</p>
                        </div>
                        <div className={styles.price}>
                            <div className={styles.label}>销售价格：<span className={styles.rmb}>¥</span><span className={styles.money}>{price}</span></div>
                        </div>
                    </div>
            }
        </>;
    }

    _saleGood = ({ title, list = [] }) => {
        return (
            <div className={styles.card}>
                {/* <div className={styles.title}>{title}</div> */}
                <ul className={styles.ul}>
                    {list.map((item) => {
                        const { id, mainImgUrl, goodsName, specName, salePrice } = item;
                        return this._saleGoodItem(id, mainImgUrl, goodsName, specName, salePrice);
                    })}
                </ul>
            </div>
        );
    }

    _saleGoodItem = (id, mainImgUrl, goodsName, specName, salePrice) => {
        const price = regFenToYuan(salePrice);
        return (
            <li key={id} className={styles.li} onClick={() => this._goodClick(id)}>
                <div className={styles.good}>
                    <img className={styles.img} src={mainImgUrl} />
                    <div className={styles.desp}>
                        <p className={styles.p1}>{goodsName}</p>
                        <p className={styles.p2}>{specName}</p>
                    </div>
                    <div className={styles.price}>
                        <div className={styles.label}><span className={styles.rmb}>¥</span><span className={styles.money}>{price}</span></div>
                    </div>
                </div>
            </li>
        );
    }

    render() {
        const { hotList } = this.state;
        const { elecList } = this.state;
        const { aroundList } = this.state;

        return (
            <div className={styles.container}>
                {/* <div className={styles.banner}>
                    <img className={styles.largeImg} src={require('@/assets/image/home_banner.png')} />
                </div> */}
                <div className={styles.header}>
                    <div className={styles.topbar}>
                        <div className={styles.nav}>
                            <div className={styles.sep}></div>
                            <a className={styles.a} href={xssd_href} target='_self'><img className={styles.icon} src={require('@/assets/image/home_icon.png')} /></a>
                            <div className={styles.sep}></div>
                            <a className={styles.a} href={location.origin}>优选行商城</a>
                            <div className={styles.sep}></div>
                        </div>
                    </div>
                </div>
                {/* <div className={styles.hot}>
                    <div className={styles.title}>
                        <p className={styles.p1}>医药用品</p>
                        <p className={styles.p2}>------------------</p>
                        <p className={styles.p3}>Medical supplies</p>
                    </div>
                    <div className={styles.goods}>
                        {hotList.map((item) => {
                            const { id, mainImgUrl, goodsName, specName, salePrice, goodsProperty } = item;
                            return this._hotGood(id, mainImgUrl, goodsName, specName, salePrice, goodsProperty);
                        })}
                    </div>
                </div> */}
                <div className={styles.sales}>
                    <div className={styles.box}>
                        {this._saleGood({ title: '家电系列', list: elecList })}
                    </div>
                    {/* <div className={styles.banner}>
                        <img className={styles.saleImg} src={require('@/assets/image/home_sale.png')} />
                    </div>
                    <div className={styles.box}>
                        {this._saleGood({ title: '生活周边', list: aroundList })}
                    </div> */}
                </div>
            </div>
        );
    }
}

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;