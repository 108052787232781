/**
 *  级联搜索
 */
import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Select, Icon } from 'antd';

const { Option } = Select;

class SearchCascade extends Component {
  state = {
    open: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ open: true });
    }, 500);
  }

  onChange = (val) => {
    const { SearchDataSource, keyIndex } = this.props;
    let selectItem = {};
    for (let i = 0; i < SearchDataSource.length; i++) {
      if (SearchDataSource[i][keyIndex] == val) {
        selectItem = SearchDataSource[i];
        break;
      }
    }
    this.props.onSearchChange(selectItem);
  }

  _renderSelectItem = (SearchDataSource, keyIndex, dataIndex) => {
    return SearchDataSource.map((item, index) => {
      if (Object.prototype.toString.call(item) == '[object String]') {
        return (<Option key={index} value={item}>{item}</Option>);
      } else if (Object.prototype.toString.call(item) == '[object Object]') {
        return (<Option key={item[keyIndex]} value={item[keyIndex]}>{item[dataIndex]}</Option>);
      }
    });
  }

  render() {
    const { open } = this.state;
    const { placeholder, SearchDataSource, keyIndex, dataIndex, value, style } = this.props;
    return (
      <div className={styles.cascade} style={style}>
        <Select
          style={{ width: 200 }}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          showSearch
          open={open}
          defaultActiveFirstOption={false}
          suffixIcon={<Icon type="search" style={{ fontSize: '16px' }} />}
          value={value}
          placeholder={placeholder}
          optionFilterProp="children"
          onChange={this.onChange}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {this._renderSelectItem(SearchDataSource, keyIndex, dataIndex)}
        </Select>
      </div>
    );
  }
}

SearchCascade.propTypes = {
  style: PropTypes.object,
  onSearchChange: PropTypes.func,// 变化时change回调函数
  placeholder: PropTypes.string, //Search-placeholder
  SearchDataSource: PropTypes.array.isRequired,//数据源
  dataIndex: PropTypes.string, // 数据源在下拉列中显示的文本属性
  keyIndex: PropTypes.string, // 数据源在下拉列中唯一的ID属性
  value: PropTypes.string, // 当前选中的内容(双向绑定)
};

export default SearchCascade;