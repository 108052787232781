/**
 * 带标题的下拉导航组件
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';

class DropdownModule extends React.Component {

  // 下拉菜单变化时触发
  _onItemChange = (selectKey) => {
    const { onChange, renderData, keyIndex } = this.props;
    if (onChange) {
      let selectItem = {};
      for (let i = 0; i < renderData.length; i++) {
        if (renderData[i][keyIndex] == selectKey) {
          selectItem = renderData[i];
          break;
        }
      }
      this.props.onChange(selectItem);
    }
  }

  // 渲染下拉菜单项
  _renderMeunItem = (dataSource, keyIndex, dataIndex) => {
    return dataSource.map((item) => {
      return (
        <Menu.Item key={item[keyIndex]}>
          <div onClick={() => { this._onItemChange(item[keyIndex]); }}>
            {item[dataIndex]}
          </div>
        </Menu.Item>
      );
    });
  }

  _renderDropdownValue = () => {
    const { placeholder, value } = this.props;
    let vNode = null;
    if (value) {
      vNode = <div className={styles.value}>{value}</div>;
    } else {
      vNode = <div className={`${styles.value} ${styles.placeholderShow}`}>{placeholder}</div>;
    }
    return vNode;
  }

  render() {
    const { label, renderData, dataIndex, keyIndex, style } = this.props;
    const menu = (
      <Menu>
        {this._renderMeunItem(renderData, keyIndex, dataIndex)}
      </Menu>
    );
    return (
      <div style={style} className={styles.container}>
        <div className={styles.label}>{label}</div>
        <div className={styles.dropdown}>
          <Dropdown overlay={menu} placement="bottomCenter">
            {this._renderDropdownValue()}
          </Dropdown>
        </div>
      </div>
    );
  }
}

DropdownModule.propTypes = {
  style: PropTypes.object, // 总样式
  label: PropTypes.string, // 标题名称
  onChange: PropTypes.func, // 下拉菜单变化时的回调函数
  placeholder: PropTypes.string, // 提示信息
  value: PropTypes.string, // 下拉列菜单内容(双向绑定)
  renderData: PropTypes.array.isRequired, // 数据源
  dataIndex: PropTypes.string.isRequired, // 指定数据源的某个属性作为下拉项文本
  keyIndex: PropTypes.string.isRequired, // 数据源唯一的ID属性
};

export default DropdownModule;