/**
 * 信息组件（标签：内容，如 姓名：张三）
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

class InfoLabel extends React.Component {

    render(){
        const { style, labelWith, label, value } = this.props;

        return(
            <div className={styles.container} style={style}>
                <div className={styles.label} style={{minWidth: labelWith}}>{label}</div>
                <div className={styles.value}>{value}</div>
            </div>
        );
    }
}

InfoLabel.propTypes = {
    style: PropTypes.object, // 总样式
    labelWith: PropTypes.number, // 标题的宽度
    label: PropTypes.string, // 标题值
     // 内容值，为字符串或数字
    value:  PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};


export default InfoLabel;