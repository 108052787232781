/**
 * 子模块头部标题组件
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

class Title extends React.Component {

    render(){
        const { label, style } = this.props;

        return(
            <div className={styles.container} style={style}>
                <div className={styles.line}></div>
                <div className={styles.label}>{label}</div>
            </div>
        );
    }
}

Title.propTypes = {
    style: PropTypes.object, // 总样式
    label: PropTypes.string // 标题名称
};

export default Title;
