import axios from 'axios';

const ajaxRequest = (url = '', data = {}, type = 'POST') => {
    let promise;
    return new Promise((resolve, reject) => {
        if (type.toUpperCase() === 'GET') {
            promise = axios({
                method: type,
                url,
                params: data
            });
        } else if (type.toUpperCase() === 'POST' || type.toUpperCase() === 'DELETE' || type.toUpperCase() === 'PUT' || type.toUpperCase() === 'PATCH') {
            promise = axios({
                method: type,
                url,
                // 方式一 服务端接收JSON格式请求体数据
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json; charset=UTF-8'
                },
                data: data
            });
        } else {
            promise = Promise.reject('请求方法入参错误:', type);
        }

        promise.then((response) => {
            if (response && response.status >= 200 && response.status < 300 && response.data.status == 200) {
                resolve(response.data);
            } else {
                reject(response.data);
            }
        }).catch((error) => {
            reject(error);
        });
    });
};

export default ajaxRequest;