/**
 * 带标题的下拉列（内容为标签格式）选择器
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const Option = Select.Option;

class TabsSelectModule extends React.Component {

    _onSelectChange = (value, itemList) => {
        const { bindThis, bindName, onChange, keyIndex, dataIndex } = this.props;
        const keyList = itemList.map((item) => {
            return { [keyIndex]: item.key, [dataIndex]: item.props.value };
        });
        if (onChange) {
            this.props.onChange(keyList);
        }
        if (bindThis && bindName) {
            bindThis.setState({ [bindName]: keyList });
        }
    }

    _renderSelectItem = (dataSource, keyIndex, dataIndex) => {
        return dataSource.map((item) => {
            return (
                <Option key={item[keyIndex]} value={item[dataIndex]}>{item[dataIndex]}</Option>
            );
        });
    }

    render() {
        const { label, renderData, dataIndex, style, selectStyle, placeholder, showSearch, keyIndex, isRequired, value } = this.props;
        let content = [];
        if (value) {
            content = value.map((item) => {
                return item[dataIndex];
            });
        }
        return (
            <div style={style} className={styles.container}>
                <div className={styles.label}>{label}<span className={styles.star} style={isRequired ? { display: 'block' } : { display: 'none' }}>*</span></div>
                <div className={styles.select}>
                    <Select
                        mode='multiple'
                        showSearch={showSearch}
                        style={selectStyle}
                        placeholder={placeholder}
                        onChange={this._onSelectChange}
                        value={content}
                    >
                        {this._renderSelectItem(renderData, keyIndex, dataIndex)}
                    </Select>
                </div>
            </div>
        );
    }
}

TabsSelectModule.defaultProps = {
    isRequired: false,
    showSearch: false,
};

TabsSelectModule.propTypes = {
    bindThis: PropTypes.object, // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    style: PropTypes.object, // 组件样式
    selectStyle: PropTypes.object, // 选择器样式
    label: PropTypes.string, // 标签名称
    onChange: PropTypes.func, // 下拉列变化时的回调函数
    placeholder: PropTypes.string, // 提示语
    renderData: PropTypes.array.isRequired, // 渲染下拉列数据源
    dataIndex: PropTypes.string.isRequired, // 数据源在下拉列中显示的文本属性
    keyIndex: PropTypes.string.isRequired, // 数据源在下拉列中唯一的ID属性
    value: PropTypes.array.isRequired, // 内容值
    showSearch: PropTypes.bool, // 是否可输入搜索
    isRequired: PropTypes.bool, // 是否显示红星
};

export default TabsSelectModule;