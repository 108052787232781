/**
 * 取消、重置、返回 按钮
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Button } from 'antd';

class CancelButtonModule extends React.Component {
    render() {
        const { style, label, ...otherProps } = this.props;
        return (
            <div className={styles.container} style={style}>
                <Button {...otherProps}>{label}</Button>
            </div>
        );
    }
}

CancelButtonModule.propTypes = {
    style: PropTypes.object, // 按钮样式
    label: PropTypes.string, // 按钮名称
};

export default CancelButtonModule;